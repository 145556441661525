import React, { memo } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';
import { customPropTypes } from '../../util';
import classnames from 'classnames';
import GatsbyLink from '../GatsbyLink/GatsbyLink';
import UniversalImage from '../UniversalImage/UniversalImage';

import './CtaCard.scss';

function CtaCard({ className, title, description, backgroundColor = 'purple', icon, link, style }) {
  return (
    <GatsbyLink
      to={link?.url}
      target={link?.target}
      className={classnames('CtaCard', backgroundColor, className, { noDescription: !description })}
      style={style}
    >
      <div className="card-wrapper">
        <UniversalImage className="icon" image={icon} />
        <h3 className="card-title">{title}</h3>
        {description && <div className="card-description">{description}</div>}
      </div>
      {link?.title && <div className="cta-title">{link.title}</div>}
    </GatsbyLink>
  );
}

CtaCard.propTypes = checkExtraProps({
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  icon: customPropTypes.image,
  link: customPropTypes.link,
  style: PropTypes.string,
});

CtaCard.defaultProps = {};

export default memo(CtaCard);
