import React, { memo, useContext } from 'react';
import classnames from 'classnames';
import { ReactComponent as Play } from '../../assets/svgs/play.svg';
import { ModalContext } from '../Layout/Layout';
import UniversalImage from '../UniversalImage/UniversalImage';
import checkExtraProps from '@jam3/react-check-extra-props';
import PropTypes from 'prop-types';
import { customPropTypes } from '../../util';

import './VideoCard.scss';

const VideoCard = ({ className, title, videoId, icon, style }) => {
  const { setVideoId, setVideoPlayerOpen } = useContext(ModalContext);
  return (
    <div
      className={classnames(className, 'VideoCard')}
      onClick={() => {
        setVideoId(videoId);
        setVideoPlayerOpen(true);
      }}
      style={style}
    >
      <div className="content">
        <div className="circle-icon">
          <Play />
        </div>
        <div className="title">{title}</div>
      </div>
      <div className="image-content">
        <UniversalImage
          className="image"
          image={icon}
          imgStyle={{ objectFit: 'cover', objectPosition: 'left center', maxWidth: 270, right: 0, left: 'unset' }}
        />
      </div>
    </div>
  );
};

VideoCard.propTypes = checkExtraProps({
  className: PropTypes.string,
  title: PropTypes.string,
  videId: PropTypes.string,
  icon: customPropTypes.image,
  style: PropTypes.string,
});

export default memo(VideoCard);
