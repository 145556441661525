import React, { memo } from 'react';
import { useTransitionDelay } from '../../../util';
import { STAGGER } from '../../../util/motion-manager/constant';
import CtaCard from '../../CtaCard/CtaCard';
import VideoCard from '../../VideoCard/VideoCard';

function CtaCardGridItem({ data, index }) {
  const transitionDelay = useTransitionDelay(STAGGER * (index + 1));

  if (data.cardType === 'video') {
    return <VideoCard className="CtaCardGridItem" style={transitionDelay} {...data} />;
  }

  let cardLink = {};

  if (data.cardType === 'link') {
    cardLink = data.link;
  } else if (data.cardType === 'download') {
    // TODO: Add global translation
    cardLink = {
      title: `Download document (${data.file?.localFile?.extension})`,
      url: data.file?.localFile?.publicURL,
      target: '_blank',
    };
  }

  return <CtaCard className="CtaCardGridItem" {...data} link={cardLink} style={transitionDelay} />;
}

CtaCardGridItem.defaultProps = {};

export default memo(CtaCardGridItem);
